export function useFileInput(props: {
  name?: string;
  id?: string;
  acceptedFormats?: string[];
}) {
  const acceptedFormats = props.acceptedFormats ?? [
    'application/pdf',
    'image/png',
    'image/jpeg',
  ];

  const draggingOver = ref(0);
  let counter = 1;
  const inputs = ref([getInputName()]);
  const deletedInputs = ref([]);
  function getInputName() {
    counter++;
    return `${props.name ?? props.id ?? 'file'}-${counter}`;
  }

  const { t } = useTrans();

  function preventDragOver(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = 'none';
    return false;
  }

  function dragover(e: DragEvent) {
    if ((e.target as HTMLElement).tagName === 'INPUT')
      draggingOver.value = acceptedFormats.includes(
        e.dataTransfer.items[0].type,
      )
        ? 1
        : 2;
    if (draggingOver.value === 2) preventDragOver(e);
  }

  function dragleave(e: DragEvent) {
    if ((e.target as HTMLElement).tagName === 'INPUT') draggingOver.value = 0;
  }

  function drop(e: DragEvent) {
    if (draggingOver.value === 2) {
      e.preventDefault();
      e.stopPropagation();
      e.dataTransfer.dropEffect = 'none';
      draggingOver.value = 0;
      return false;
    }
    draggingOver.value = 0;
  }

  return {
    inputs,
    deletedInputs,
    draggingOver,
    dragover,
    dragleave,
    drop,
    preventDragOver,
    getInputName,
    acceptedFormats,
    t,
  };
}
