<template>
  <div>
    <FormLabel
      v-if="!$slots.label && label"
      :label="label"
      :required="required"
    />
    <div
      :class="[
        {
          'bg-primary-lightest !border-primary-base': draggingOver === 1,
          'bg-status-danger-base-opc10 border-status-danger-base':
            draggingOver === 2,
        },
        classes,
      ]"
      class="relative flex flex-col-reverse border-dashed p-sm border-1 border-border-light rounded-alt-sm mt-[5px] gap-[10px]"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <template v-if="isMobile">
        <Popup
          v-if="info || $slots.info"
          trigger-class="top-[10px] right-[15px] z-[5]"
        >
          <template #trigger>
            <Icon
              icon-class="fad fa-circle-info"
              style="
                --fa-primary-color: var(--thm-text-base);
                --fa-secondary-color: var(--thm-status-warn-base);
                --fa-secondary-opacity: 1;
              "
              @dragover="preventDragOver"
            />
          </template>
          <template #content>
            <template v-if="info">
              {{ t(info) }}
            </template>
            <template v-else-if="$slots.info">
              <slot name="info" />
            </template>
          </template>
        </Popup>
      </template>
      <template v-else>
        <DynamicPopup
          v-if="info || $slots.info"
          wrapper-classes="top-[10px] right-[15px] z-[5]  absolute !h-[32px] !w-[16px] flex justify-center items-center cursor-pointer mt-[-8px]"
        >
          <template #trigger>
            <Icon
              icon-class="fad fa-circle-info"
              style="
                --fa-primary-color: var(--thm-text-base);
                --fa-secondary-color: var(--thm-status-warn-base);
                --fa-secondary-opacity: 1;
              "
              @dragover="preventDragOver"
            />
          </template>
          <template #content>
            <InfoFrame :has-cancel="true" class="min-w-[315px] max-w-[450px]"
              ><slot name="content" /><template v-if="info">
                {{ t(info) }}
              </template>
              <template v-else-if="$slots.info">
                <slot name="info" />
              </template>
            </InfoFrame>
          </template>
        </DynamicPopup>
      </template>
      <div
        v-for="(inputName, i) in inputs"
        v-show="!deletedInputs.includes(inputName)"
        :key="i"
        class="item"
        @dragover="
          (e) => {
            if (i !== inputs.length - 1) return preventDragOver(e);
          }
        "
      >
        <div>
          <div v-if="i === inputs.length - 1" class="p-sm">
            <div
              v-if="draggingOver === 2"
              class="flex items-center justify-center text-status-danger-base gap-[8px]"
            >
              <Icon icon-class="fal fa-ban" classes="text-status-danger-base" />
              {{ t('formFields.inputFile.fileTypeNotAllowed') }}
            </div>
            <div v-else>
              <div class="text-center touch:hidden">
                {{ t('formFields.inputFile.dropHereOr') }}
                <span class="font-bold text-primary-base">{{
                  t('formFields.inputFile.selectFromComputer')
                }}</span>
              </div>
              <div
                class="items-center justify-center hidden font-bold touch:flex text-primary-base"
              >
                {{ t('formFields.inputFile.selectFiles') }}
              </div>
            </div>
          </div>
          <div
            v-if="!deletedInputs.includes(inputName)"
            class="flex justify-between gap-xs"
          >
            <FileInput
              :name="inputName"
              :accepted-formats="acceptedFormats.join(',')"
              :disabled="i !== inputs.length - 1"
              class="max-w-[calc(100%-30px)]"
              @change="
                () => {
                  if (i === inputs.length - 1) {
                    inputs.push(getInputName());
                    $emit('change');
                  }
                }
              "
            />
            <div
              v-if="i !== inputs.length - 1"
              class="h-[24px] w-[25px] rounded-full flex justify-center items-center cursor-pointer"
              @click="
                () => {
                  deletedInputs.push(inputName);
                  $emit('change');
                }
              "
            >
              <Icon icon-class="fal fa-trash" classes="text-primary-base" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-sm text-border-dark mt-[10px]">
      <slot name="acceptedFormats">
        {{ t('formFields.inputFile.acceptedFormats') }}
      </slot>
    </div>
  </div>
</template>
<script setup lang="ts">
import Popup from '~~/src/components/formFields/components/formInfoPopup.vue';
import {
  Icon,
  useFileInput,
  FileInput,
  FormLabel,
  DynamicPopup,
  InfoFrame,
} from '@/complib';
import { SSR_safe_mq_breakpointIsMobile } from '~/injectionSymbols';

const props = defineProps({
  label: {
    type: String,
    required: false,
    default: '',
  },
  id: {
    type: String,
    required: false,
    default: undefined,
  },
  required: {
    type: Boolean,
    default: true,
  },
  name: {
    type: String,
    default: '',
  },
  info: {
    type: String,
    default: '',
  },
  acceptedFormats: {
    type: Array as PropType<string[]>,
    default: () => null,
  },
  classes: {
    type: String,
    default: '',
  },
});

defineEmits<{
  (e: 'change'): void;
}>();

const isMobile = inject(SSR_safe_mq_breakpointIsMobile);

const {
  inputs,
  deletedInputs,
  draggingOver,
  dragover,
  dragleave,
  drop,
  preventDragOver,
  getInputName,
  acceptedFormats,
  t,
} = useFileInput(props);
</script>
<style lang="postcss" scoped>
.item:not(:last-child) {
  @apply bg-background-base rounded-alt-sm p-[6px];
  z-index: 5;
  :deep(input) {
    @apply appearance-none outline-0 ring-0 h-0;
  }
}

.item:last-child {
  :deep(input) {
    @apply appearance-none outline-0 ring-0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 2;
    color: transparent;
  }
}

:deep(.input) {
  @apply appearance-none outline-0 ring-0 absolute;
  color: transparent;
}

:deep(.input::file-selector-button) {
  @apply appearance-none;
  font-size: 0;
  padding: 0;
  border: 0;
}
</style>
