<template>
  <div>
    <input
      ref="input"
      class="input noGlobal"
      type="file"
      :name="name"
      :accept="acceptedFormats"
      :disabled="disabled"
      @change="
        (e) => {
          value = (e.target as HTMLInputElement).files[0];
          $emit('change');
        }
      "
    />
    <div v-if="value">
      <FileDisplay
        :type="value.type"
        :name="value.name"
        :size="value.size"
        @click="openFileInNewTab"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { FileDisplay } from '@/complib';

defineProps({
  acceptedFormats: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  name: {
    type: String,
    required: false,
    default: '',
  },
});

defineEmits<{
  (e: 'change'): void;
}>();

const value = ref<File>(null);
const input = ref<HTMLInputElement>(null);

function openFileInNewTab() {
  if (input.value) {
    const file = input.value.files[0];
    const url = URL.createObjectURL(file);
    window.open(url);
    URL.revokeObjectURL(url);
  }
}
</script>
